<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BrunaForm ref="fechaConsultaRef" :fields="this.prediccion.fields" :card="this.prediccion.card" :datePickerFormatOptions="datePickerFormatOptions">
          <template v-slot:footer>
            <b-button variant="primary" class="float-right" @click="updateCharts()">Consultar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <faenas :metrics="metrics" :sidebarContent="sidebarContent" :query="query" chart="historico"></faenas>

    <b-row>
      <b-col>
        <ag-grid-table :dataUrl="data" :configUrl="config" :helper="true">
        </ag-grid-table>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue';
import Faenas from './Faenas.vue';
var qs = require('qs');


export default {
  data() {
    //fecha ultimo registro, los meses van desde el 0 al 11 
    const last_date = new Date(2022,11,31);
    const default_fin = last_date;
    const default_inicio = new Date(last_date.getFullYear(), last_date.getMonth(), last_date.getDate() - 30);
    let query = qs.stringify({ inicio: default_inicio, fin: default_fin});

    return {
      config: useApiServices.historicoPrediccionDemoConfig,
      data: useApiServices.historicoPrediccionDemoData,
      query: query,
      itemsData: [],
      datePickerFormatOptions: {'year': 'numeric', 'month': '2-digit', 'day': '2-digit'},
      metrics: [
            {'name': 'Coya Sur' , 'value': '', 'show': true},
            {'name': 'Antofagasta' , 'value': '', 'show': false},
            {'name': 'Salar Atacama' , 'value': '', 'show': false},
            {'name': 'Tocopilla' , 'value': '', 'show': false},
            {'name': 'Planta química litio Carmen' , 'value': '', 'show': false},
            {'name': 'Nueva Victoria' , 'value': '', 'show': false},
            {'name': 'Pedro de Valdivia' , 'value': '', 'show': false},
            {'name': 'Pampa Blanca' , 'value': '', 'show': false},
            {'name': 'Maria Elena' , 'value': '', 'show': false},
            {'name': 'Iquique' , 'value': '', 'show': false},
            {'name': 'Santiago' , 'value': '', 'show': false}
          ],
      prediccion: {
        card: {
          title: "Elige la fecha que deseas consultar",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Predicciones',
            body: "Para seleccionar el período que desea visualizar, seleccione los rangos de fechas y luego haga clic en “consultar”."
          }
        },
        fields: [
          {
            label: "Fecha inicio",
            type: "datepicker",
            id: 'fecha_inicio',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: default_inicio
          },
          {
            label: "Fecha fin",
            type: "datepicker",
            id: 'fecha_fin',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: default_fin
          },
        ]
      },
      sidebarContent: {
        body: "<ul><li>Para poder visualizar los gráficos de las predicciones que desee, prenda y apague los botones.</li></ul>"
      }
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    BrunaElementLoader,
    Faenas
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },
  methods: {
    refreshInfo() {
      this.updateCards();
      // this.$refs.tablaHistoricoCarga.refreshRows();
    },

    updateCards() {
      axios.get(useApiServices.statisticsAccidentabilidadDemo + "/historico")
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    },

    updateCharts() {
      const default_inicio = this.prediccion.fields[0].value;
      const default_fin = this.prediccion.fields[1].value;
      this.query = qs.stringify({ inicio: default_inicio, fin: default_fin, faena: 'Salar Atacama' });

    }
  },
};
</script>

<style scoped></style>
