<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>

    <faenas :metrics="metrics" :sidebarContent="sidebarContent" chart="diario"></faenas>

    <b-row>
      <b-col>
        <ag-grid-table :dataUrl="data" :configUrl="config" :helper="true">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import useApiServices from '@/services/useApiServices.js';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue';
import Faenas from './Faenas.vue';


export default {
  data() {
    return {
      itemsData: [],
      chart1: useApiServices.diarioPrediccionesIncidentesChart,
      config: useApiServices.ultimaPrediccionConfig,
      data: useApiServices.ultimaPrediccionData,
      metrics: [
        { 'name': 'Coya Sur', 'value': '', 'show': true },
        { 'name': 'Antofagasta', 'value': '', 'show': false },
        { 'name': 'Salar Atacama', 'value': '', 'show': false },
        { 'name': 'Tocopilla', 'value': '', 'show': false },
        { 'name': 'Planta química litio Carmen', 'value': '', 'show': false },
        { 'name': 'Nueva Victoria', 'value': '', 'show': false },
        { 'name': 'Pedro de Valdivia', 'value': '', 'show': false },
        { 'name': 'Pampa Blanca', 'value': '', 'show': false },
        { 'name': 'Maria Elena', 'value': '', 'show': false },
        { 'name': 'Iquique', 'value': '', 'show': false },
        { 'name': 'Santiago', 'value': '', 'show': false }
      ],
      sidebarContent: {
        body: "<ul><li>Para poder visualizar los gráficos de las predicciones que desee, prenda y apague los botones.</li><li>Se mostrarán los gráficos de pie con la probabilidad de ocurrencia de un incidente en cada Faena.</li></ul>"
      }
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaElementLoader,
    Faenas
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },
  methods: {
    refreshInfo() {
      this.updateCards();
      // this.$refs.tablaHistoricoCarga.refreshRows();
    },

    updateCards() {
      axios.get(useApiServices.statisticsAccidentabilidadDemo + "/diario")
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    }
  },
};
</script>

<style scoped></style>
