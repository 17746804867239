<template>
  <div>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ $t(cardTitle) }}</b-card-title>
        <b-card-sub-title class="mb-2">{{ $t(cardSubTitle) }}</b-card-sub-title>
      </div>
      <!-- Card Actions -->
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
          </li>
        </ul>
      </div>
    </div>
    <div>
      <b-sidebar id="sidebar-1" title="Predicciones de incidentes diarios" shadow bg-variant="dark" text-variant="light"
        width="450px" right backdrop>
        <div class="px-2 py-2">
          <span class="" v-html="$t(sidebarContent.body)" />
        </div>
      </b-sidebar>
    </div>

    <!-- Card Body  -->
    <b-card-body style="width: 100%">
      <b-row>
        <b-col lg="3" xl="3" v-for="metric in metrics" :key="metric.name">
          <b-form-checkbox v-model="metric.show" :value="true" :unchecked-value="false" switch>
            {{ metric.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col  :md="colChart()" :lg="colChart()" :xl="colChart()" v-for="metric in metrics" :key="metric.name" v-show="metric.show">

          <bruna-element-loader :dataUrl="baseUrl + '/' + metric.name + '?' + query"></bruna-element-loader>

        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  </div>
</template>
  
<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
const axios = require("axios");
import VueSlider from 'vue-slider-component';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue';
import useApiServices from "@/services/useApiServices.js";


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VueSlider,
    BFormInput,
    BFormCheckbox,
    BrunaElementLoader
  },
  directives: {
  },
  data: function () {

    return {
      selected: [],
      baseUrl: useApiServices.chartAccidentabilidadDemo  + "/" + this.chart,
      options: this.metrics.map((metric) => { return { text: metric.name, value: metric } }),
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    colChart() {
      return this.chart == 'diario' ? "6" : "12";
    },
    simulate() {
      this.$emit('change')
    },
    searchChange(value) {
      this.$emit('search-change', value);
    },
    onDragEnd(metric) {
      console.log(metric + " drag_end")
    },
    onChange(metric, value) {
      /*
      if (this.timeout)
      {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() =>
      {
        this.$emit('change', metric, value);
      }, 3000); // delay
*/
    }
  },
  props: {
    sidebarContent: {
      type: Object,
      default: {
      }
    },
    metrics: {
      type: Array,
      required: true,
      default: () => []
    },
    chart: {
      type: String,
      required: true,
      default: () => 'diario'
    },
    cardTitle: {
      type: String,
      default: () => "Predicciones de incidentes diarios"
    },
    cardSubTitle: {
      type: String,
      default: () => "Seleccione las faenas que desea graficar"
    },
    query: {
      type:String,
      default: () => ''
    }
  },
};
</script>
  
<style lang="scss">

</style>


<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>